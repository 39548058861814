(function () {
	var origReady = $.fn.ready;
	$.fn.ready = function (f) {
		if ($.isReady) {
			setTimeout(function () {
				f($);
			}, 0);
		} else {
			origReady.apply(this, arguments);
		}
	};
})();
