$(function () {

	$("#searchsort").change(function () {
		$("#sort").val($("#searchsort").val());
		$("#send").click();
	});

	var touching = false;
	$(".lecture-search-results-item-inner")
		.click(function (e) {
			if (touching && !$(this).hasClass("touched")) {
				$(".lecture-search-results-item-inner").removeClass("touched");
				$(this).addClass("touched");
				e.preventDefault();
			}
		})
		.bind("touchstart", function () {
			if (!touching) {
				touching = true;
				$(".lecture-search-results-item-inner").addClass("touching");
			}
		});
	$(document).click(function (e) {
		if ($(e.target).closest(".lecture-search-results-item-inner").length === 0) {
			$(".lecture-search-results-item-inner").removeClass("touched");
		}
	});

	var showLectures = function () {
		while (true) {
			var hidden = $(".lecture-search-results-hidden");
			if (hidden.length === 0) {
				break;
			}

			var offset = $(".lecture-search-results-item:not(.lecture-search-results-hidden):last").offset().top - $(window).scrollTop() - $(window).height();
			if (offset > 200) {
				break;
			}

			hidden.first().removeClass("lecture-search-results-hidden");
		}
	};
	$(window).scroll(showLectures);

	window.lectureResultsUpdate = function () {
		showLectures();
	};

});