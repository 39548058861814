if (typeof CKEDITOR != 'undefined') {
	CKEDITOR.plugins.add('nbsp',
		{
			init: function (editor) {
				this.path = '/static/js/lib/ckeditor/plugins/nbsp/';

				editor.addCommand('insertNbsp', {
					exec: function (editor) {
						editor.insertHtml('&nbsp;');
					}
				});

				editor.ui.addButton('Nbsp', {
					label: editor.lang.nbsp.button,
					command: 'insertNbsp',
					icon: this.path + 'images/nbsp.png'
				});

				editor.keystrokeHandler.keystrokes[CKEDITOR.SHIFT + 32 /* SPACE */] = 'insertNbsp';
			},

			lang: ['cs', 'en']
		});
}