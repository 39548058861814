$(document).ready(function ($) {

	// Match height
	$('.item-eq').matchHeight();
	$('.news .item h3').matchHeight();
	$('.news .item .text').matchHeight();
	/*$('.posts-inline .item-inner > *').css('height','auto').matchHeight();*/
	var activeItem = null;
	var firstItem = null;
	$('.side-nav li').each(function(){
		var item = $(this);
		if (!firstItem) {
			firstItem = item.find('>a').text();
		} else if (!activeItem && $(this).hasClass('active')) {
			activeItem = item.find('>a').text();
		}
		if (item.find('>ul').length == 0) {
			item.addClass('empty');
		}
	});

	$('.side-nav .trigger').text(activeItem ? activeItem : firstItem).on('click', function(){
		$(this).parent().toggleClass('open');
		if ($(this).parent().children(".ib").children("li").length === 1) {
			$(this).parent().children(".ib").children("li").toggleClass("open");
		}
	});

	// Fixer
	$(window).on('load scroll',function() {
		if ( $('.fixer').length ) {
			$('.fixer-start').each(function() {
				var scrollTop = $(window).scrollTop();
				var winH = $(window).height();
				var fixElWrapper = $(this);
				var fixEl = fixElWrapper.find('.fixer');
				var offset = 0;
				if ( fixEl.attr('data-offset') ) {
					var offset = fixEl.data('offset');
				}

				var fixElWrapperTop = fixElWrapper.offset().top + offset;

				var fixElHeight = fixEl.height();
				var end = $('.fixer-end').height() + $('.fixer-end').offset().top - fixEl.height();

				// Top panel
				if ( scrollTop > fixElWrapperTop ) {
					fixEl.addClass('fixed');
					// Hide in the end
					if ( scrollTop > end ) {
						fixEl.fadeOut();
					}
					else {
						fixEl.fadeIn();
					}
				}
				else {
					fixEl.removeClass('fixed');
				}
			});
		}
	});

	/**********************************************
	POSTS INLINE
	**********************************************/

	$('.sh-trig-more').click(function() {
		var trig = $(this);
		var item = $(this).closest('.item');
		var id = trig.attr('data-content');
		var container = item.closest('.posts-inline');
		var wide = container.hasClass('posts-inline-wide');

		var rowItems = 1;
		var y = container.find('.item:first').offset().top;
		container.find('.item:not(:first)').each(function () {
			if ($(this).offset().top != y) {
				return false;
			}
			rowItems++;
		});

		var index = ( Math.floor((item.index() / 2)) + 1); // pořadí .itemu bez skrytých .itemů
		var indexCeil = wide ? index - 1 : ( Math.ceil(index / rowItems) * rowItems ) - 1; // pořadí poslední položky na řádku
		var count = trig.closest('.posts-inline').find('.item').length; // celkový počet položek

		// Poslední řádek
		if ( indexCeil >= count ) {
			indexCeil = count - 1;
		}

		$('.sh-trig-more.active').not(this).click();

		if ( $(window).width() > 577 ) {
			// 577px and higher
			if ( ! trig.hasClass('active') ) {
				var el = $('#'+id);
				var clone = el.clone();
				var endRow = trig.closest('.posts-inline').find('.item').eq(indexCeil);

				trig.addClass('active');
				trig.find('.text').text( $(this).attr('data-less') );

				clone.addClass('clone').addClass('active').html();

				// Insert clone after end row
				clone.insertAfter(endRow);

				// Animate to item
				$("html:not(:animated),body:not(:animated)").animate({ scrollTop: item.offset().top },300, function () {

				});
			} else {
				trig.removeClass('active');
				trig.find('.text').text( $(this).attr('data-more') );

				$('.item-hidden.clone').remove();
			}
		}
		else {
			// 577px and less
			if ( ! trig.hasClass('active') ) {
				var el = $('#'+id);
				var clone = el.clone();
				var item = trig.closest('.item');

				trig.addClass('active');
				trig.find('.text').text( $(this).attr('data-less') );

				clone.addClass('clone').addClass('active').html();

				// Insert clone after opened item
				clone.insertAfter(item);

				// Animate to item
				$("html:not(:animated),body:not(:animated)").animate({ scrollTop: item.offset().top },300, function () {

				});
			} else {
				trig.removeClass('active');
				trig.find('.text').text( $(this).attr('data-more') );

				$('.item-hidden.clone').remove();
			}
		}
	});

	// Close click
	$('html').delegate('.posts-inline .item-hidden .close, .posts-inline .item-hidden .close-link','click',function() {
		var el = $(this).closest('.item-hidden');
		var id = el.attr('id');

		$('[data-content="'+ id +'"]').trigger('click');

		if( el.hasClass('clone') ) {
			el.remove();
		}
	});

	$('.posts-inline .item .img').click(function() {
		$(this).closest('.item').find('.more').trigger('click');
	});
	$('.posts-inline .item h3 a').click(function() {
		$(this).closest('.item').find('.more').trigger('click');
	});

	$(window).on('resize', function () {
		$('.posts-inline .more.active').trigger('click').trigger('click');
	});

	// Go to default when adjusting viewport
	// $(window).on('resizeEnd',function() {
	// 	$('.sh-trig-more.active').click();
	// });

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

	/**********************************************
	MOBILE NAVIGATION
	**********************************************/

	$(function() {
		var mobileNav = $('.mobile-nav');
		var mobileNavOverlay = $('.mobile-nav-overlay');

		function showNav() {
			mobileNav.addClass('active');
			mobileNavOverlay.fadeIn();
			$('body').addClass('mobile-nav-opened');
		}

		function hideNav() {
			mobileNav.removeClass('active');
			mobileNavOverlay.fadeOut();
			$('body').removeClass('mobile-nav-opened');
		}

		$('.nav-btn').click(function() {
			if ( ! mobileNav.hasClass('active') ) {
				showNav();
			}
			else {
				hideNav();
			}
			return false;
		});

		// Mobile nav close
		$('.mobile-nav .close').click(function(){
			hideNav();
		});

		// Mobile nav overlay close
		$('.mobile-nav-overlay').click(function(){
			hideNav();
		});

		$('.mobile-nav li a').each(function() {
			if ( $(this).siblings('ul').length > 0 ) {
				$(this).append('<span class="more"></span>');
			}
		});

		// Mobile nav accordion
		$('.mobile-nav li a').click(function() {
			if ( $(this).siblings('ul').length > 0 ) {
				if ( ! $(this).siblings('ul').hasClass('active') ) {
					//$('.mobile-nav ul ul').removeClass('active');
					$(this).siblings('ul').addClass('active');
				} else {
					$(this).siblings('ul').removeClass('active');
				}
				return false;
			}
		});
	});

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}