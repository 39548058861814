function initSelect2multi(selector, parentSelector, itemClass, data)
{
	$(function () {
		var mouseoverCallbacks = $.Callbacks();

		var select = $(selector);
		select.find("option[value=\"\"]:not(:first)").remove();
		var value = select.val();

		select.empty().select2({
			data: data,
			minimumResultsForSearch: -1,
			templateResult: function (item) {
				var elm = $("<span>" + item.text + "</span>");
				if (item.children !== undefined) {
					setTimeout(function () {
						var mouseover = false;
						var group = elm.parents("li:first").addClass("select2-expandable-optgroup");
						var sub = group.children("ul");
						var parent = group.parent();
						var noGroupSiblings = parent.children("li:not(.select2-expandable-optgroup)");
						var touch = true;
						var disableTouchTimeout = null;
						group
							.bind("touchend", function () {
								touch = true;
								clearTimeout(disableTouchTimeout);
								disableTouchTimeout = setTimeout(function () { touch = false; }, 300);
							})
							.click(function () {
								if ((touch || !sub.is(":visible")) && group.hasClass("select2-expandable-mouseover")) {
									sub.filter(":not(.select2-expandable-sub-selected)").show();
									group.removeClass("select2-expandable-mouseover");
								} else if (!group.hasClass("select2-expandable-mouseover")) {
									sub.filter(":not(.select2-expandable-sub-selected)").toggle();
								} else {
									return;
								}
								mouseover = false;
							})
							.mouseover(function () {
								if (!sub.is(":visible")) {
									mouseoverCallbacks.fire();
									sub.filter(":not(.select2-expandable-sub-selected)").show();
									group.addClass("select2-expandable-mouseover");
									mouseover = true;
								} else if (group.hasClass("select2-expandable-mouseover")) {
									mouseover = true;
								} else {
									mouseoverCallbacks.fire();
								}
							});

						var mouseLeaved = function () {
							if (mouseover) {
								mouseover = false;
								setTimeout(function () {
									if (!mouseover) {
										sub.filter(":not(.select2-expandable-sub-selected)").hide();
										group.removeClass("select2-expandable-mouseover");
									}
								}, 300);
							}
						};

						parent.mouseleave(mouseLeaved);
						noGroupSiblings.mouseover(mouseLeaved);

						mouseoverCallbacks.add(function () {
							if (group.hasClass("select2-expandable-mouseover")) {
								sub.filter(":not(.select2-expandable-sub-selected)").hide();
								group.removeClass("select2-expandable-mouseover");
							}
						});

						if (group.find(".select2-results__option--highlighted").length !== 0) {
							group.addClass("select2-expandable-sub-selected");
						} else {
							group.removeClass("select2-expandable-sub-selected");
							sub.hide();
						}

						touch = false;
					});
				} else {
					elm.addClass(itemClass);
				}
				return elm;
			}
		}).uniform();

		if (value) {
			select.val(value).trigger("change");
		}

		var parentTouch = false;
		var parentTouchTimeout = null;
		select.parents(parentSelector)
			.bind("touchend", function () {
				parentTouch = true;
				clearTimeout(parentTouchTimeout);
				setTimeout(function () {
					parentTouch = false;
				}, 400);
			});

		select.on("select2:open", function () {
			var container = $(".select2-container--open").css("opacity", 0);
			if (parentTouch) {
				container.addClass("select2-expandable-opened-by-touch");
				container.removeClass("select2-expandable-opened-by-mouse");
			} else {
				container.addClass("select2-expandable-opened-by-mouse");
				container.removeClass("select2-expandable-opened-by-touch");
			}
			setTimeout(function () {
				$(window).trigger("scroll");
				container.css("opacity", "");
			}, 300);
		});
	});
}