$(function () {
	$("[data-multicheckbox-validation]").each(function () {
		var data = $(this).data("multicheckbox-validation");
		var id = $(this).find("input[type=checkbox]")
			.addClass(data.inputClass)
			.first()
			.prop("id");
		$("<div class='error-placement-wrapper'></div>").attr("data-element-id", id).appendTo(this);
		$.validator.addClassRules(data.inputClass, {
			require_from_group: [1, "." + data.inputClass]
		});
		$.validator.messages.require_from_group = $.validator.format(data.message);
	});
});