(function () {

	$(window)
		.resize(function () {
			$("#lecture-external-content > iframe, #lecture-external-content > p > iframe").css({
				width: ($(window).width() - 100) + "px",
				height: ($(window).height() - 100) + "px"
			});
		})
		.trigger("resize");

	$(document).on("click", ".external-content-fullscreen-button", function () {
		var iframes = $(this).parent().find("#lecture-external-content iframe");
		if (iframes.length > 0) {
			if (iframes[0].requestFullscreen) {
				iframes[0].requestFullscreen();
			} else if (iframes[0].webkitRequestFullscreen) { /* Safari */
				iframes[0].webkitRequestFullscreen();
			} else if (iframes[0].msRequestFullscreen) { /* IE11 */
				iframes[0].msRequestFullscreen();
			}
		}
	});

	var closeConfirmed = false;
	window.openLectureExternalContent = function () {
		closeConfirmed = false;
		$.fancybox.open("#lecture-external-content", {
			beforeClose: function () {
				if (closeConfirmed) {
					return true;
				}

				$("#lecture-external-content-confirm-close").appendTo("body").show();

				return false;
			}
		});
		$("#lecture-external-content .external-content-fullscreen-button")
			.appendTo($("#lecture-external-content").closest(".fancybox-skin"));
		$(window).trigger("resize");
	};

	$("#lecture-external-content-confirm-close-yes").click(function () {
		closeConfirmed = true;
		$.fancybox.close();
		$("#lecture-external-content-confirm-close").hide();
	});
	$("#lecture-external-content-confirm-close-no").click(function () {
		$("#lecture-external-content-confirm-close").hide();
	});

})();
