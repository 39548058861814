$(function () {
	
	var localStorage = window.localStorage;
	if (!localStorage) {
		localStorage = {
			getItem: function (key) { return "0"; },
			setItem: function (key, value) {}
		};
	}

	$(".side-popup-header, .side-popup-close").click(function () {
		$(".side-popup").toggleClass("side-popup-hidden");
		localStorage.setItem("side-popup-hidden", $(".side-popup").hasClass("side-popup-hidden") ? "1" : "0");
	});
	
	var update = function () {
		$(".side-popup-content").css("min-height", $(".side-popup-header").outerWidth() + "px");
	};
	$(window).resize(update);
	update();
	
	if (localStorage.getItem("side-popup-hidden") !== "1") {
		$(".side-popup").removeClass("side-popup-hidden");
	}

});
