$(function () {
	setInterval(function () {

		$(".jwplayer video").each(function () {
			$.each(this.textTracks, function (i, track) {
				if (track.subtitleShiftEvent || !track.cues) {
					return true;
				}

				$.each(track.cues, function (j, cue) {
					if (cue.line === "auto") {
						console.log(cue);
						cue.line = -3;
						track.removeCue(cue);
						track.addCue(cue);
					}
				});

				track.subtitleShiftEvent = true;
			});
		});

	}, 100);
});