/**********************************************
 SCROLLSPY NAVIGATION
 **********************************************/

$(function () {
	var topMenu = $(".scrollspy");

	if (topMenu.length) {
		// Cache selectors
		var lastId,
				topMenuHeight = topMenu.outerHeight(),
				// All list items
				menuItems = topMenu.find("a"),
				// Anchors corresponding to menu items
				scrollItems = menuItems.map(function () {
					var selector = $(this).attr("href");
					if (selector.length < 1 || selector.charAt(0) != '#' || !$($(this).attr("href")).length) {
						return null;
					} else {
						return $($(this).attr("href"));
					}
				});

		// Bind to scroll
		$(window).bind('load scroll', function () {
			// Get container scroll position
			var fromTop = $(this).scrollTop() + 10;

			// Get id of current scroll item
			var cur = scrollItems.map(function () {
				if ($(this).offset().top < fromTop)
					return this;
			});
			// Get the id of the current element
			cur = cur[cur.length - 1];
			var id = cur && cur.length ? cur[0].id : "";

			if (lastId !== id) {
				lastId = id;
				// Set/remove active class
				menuItems
						.parent().removeClass("active")
						.end().filter("[href=#" + id + "]").parent().addClass("active");
			}
		});
	}
});
