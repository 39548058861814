$(function () {
	$(".popup-video-opener").each(function () {
		var elm = $(this);
		elm.click(function () {
			var containerId = elm.attr("data-video-container");
			if (!containerId) {
				containerId = "video-container";
			}
			$.fancybox.open("<div id='" + containerId + "'></div>");
			var interval = setInterval(function () {
				if ($("#" + containerId).length === 0) {
					clearInterval(interval);
				}
				$(window).trigger("resize");
			}, 100);
		});
		
		var updateSize = function () {
			var width = Math.min(1200, $(window).width() - 70);
			var link =elm.attr("href");
			if (/witdh=\d+/.test(link)) {
				link = link.replace(/width=\d+/, "width=" + width);
			} else {
				if (/\?/.test(link)) {
					link += "&";
				} else {
					link += "?";
				}
				link += "width=" + width;
			}
			elm.attr("href", link);
		};
		updateSize();
		$(window).resize(updateSize);
	});
});
