var maps = [];
var geocoder;

jQuery(document).ready(function($){
	try {
		geocoder = new google.maps.Geocoder();
		$('.gmap').each(function () {
			init_map($(this));
		});
	} catch (e) {
		console.log(e);
	}
});

function init_map( mapWrapper ) {
	$markers = mapWrapper.find('.marker')
	var args = {
		zoom: 7,
		center: new google.maps.LatLng(49.8, 15.6),
		draggable: true,
		scrollwheel: false,
		disableDoubleClickZoom: false,
		panControl: false,
		zoomControl: true,
		scaleControl: false,
		mapTypeControl: false,
		streetViewControl: false,
		overviewMapControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};

	var mapIndex = maps.length + 1;
	maps[mapIndex] = new google.maps.Map( mapWrapper[0], args);

	maps[mapIndex].markers = [];

	var styles = [{
		featureType: "all",
		elementType: "all",
		stylers: [{ saturation: -100} ]
	}];

	var styledMapOptions = {
		map: maps[mapIndex]
	};

	var mainmap = new google.maps.StyledMapType(styles,styledMapOptions);

	maps[mapIndex].mapTypes.set('site_map', mainmap);
	maps[mapIndex].setMapTypeId('site_map');

	// add markers
	$markers.each(function() {
		if ($(this).attr('data-lat') && $(this).attr('data-lng')) {
			// a marker with a custom icon?
			var customMarker = false;
			if ($(this).attr('data-icon')) {
				customMarker = {
					url: $(this).attr('data-icon'),
					size: new google.maps.Size(32, 32),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(9, 32),
					scaledSize: new google.maps.Size(32, 32),
				};
			}
			add_marker(
				new google.maps.LatLng($(this).attr('data-lat'), $(this).attr('data-lng')),
				$(this).html(),
				mapIndex,
				false,
				customMarker
			);
		}
	});
	new markerClusterer.MarkerClusterer({ markers: maps[mapIndex].markers, map: maps[mapIndex] });

	// add address markers
	// use timeout because of geocoder limits
	// it is async, so center map after each new maker
	// and save GPS after successfull geocoder query
	var timeout = 0;
	$markers.each(function() {
		if ($(this).attr('data-address')) {
			setTimeout(function() {
				add_address_marker(
					$(this).attr('data-address'),
					$(this).html(),
					mapIndex,
					$(this).attr('data-school-id'),
					$(this).attr('data-address-index')
				);
			}.bind(this), timeout);
			timeout += 1000;
		}
	});

	center_map(mapIndex);
}

function add_marker(position, content, mapIndex, centerMap, customMarker) {

	if (customMarker) {
		var marker = new google.maps.Marker({
			map: maps[mapIndex],
			position: position,
			icon: customMarker,
			optimized: false,
		});
	} else {
		var marker = new google.maps.Marker({
			map: maps[mapIndex],
			position: position,
			optimized: false,
		});
	}

	maps[mapIndex].markers.push(marker);

	if (content) {
		var infowindow = new google.maps.InfoWindow({
			content		: content
		});
		google.maps.event.addListener(marker, 'click', function() {
			infowindow.open(maps[mapIndex], marker);
		});
	}
	if (centerMap) {
		center_map(mapIndex);
	}
}

function add_address_marker(address, content, mapIndex, schoolId, addressIndex) {
	geocoder.geocode({
		'address': address
	}, function(results, status) {
		if (status === google.maps.GeocoderStatus.OK) {
			var location = results[0].geometry.location;
			var position = new google.maps.LatLng(location.lat(), location.lng());
			add_marker(position, content, mapIndex, false);
			// save GPS on school address
			if (schoolId && addressIndex != '') {
				$.ajax({
					url: '/save-school-gps/'+schoolId+'/'+addressIndex+'/'+location.lat()+'/'+location.lng()
				});
			}
		} else {
			console.log('['+schoolId+'] '+address+' -> '+status);
		}
	});
}

function center_map(mapIndex) {
	var bounds = new google.maps.LatLngBounds();

	jQuery.each(maps[mapIndex].markers, function(i, marker) {
		var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
		bounds.extend(latlng);
	});
	if (maps[mapIndex].markers.length == 1) {
		maps[mapIndex].setCenter(bounds.getCenter());
	    maps[mapIndex].setZoom(16);
	} else {
		maps[mapIndex].fitBounds(bounds);
	}
}
