$(document).on("click", ".lecture-box .preview", function () {
	$(this).closest(".lecture-box").addClass("opened");
});
$(document).on("click", ".lecture-box .close", function () {
	$(this).closest(".lecture-box").removeClass("opened");
});

$(function () {
	if (/#l\d+$/.test(window.location.hash)) {
		$(window.location.hash + ".lecture-box").addClass("opened");
	}
});
