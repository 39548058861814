$.datePicker.defaults.strings = {
	months: [
		"Leden",
		"Únor",
		"Březen",
		"Duben",
		"Květen",
		"Červen",
		"Červenec",
		"Srpen",
		"Září",
		"Říjen",
		"Listopad",
		"Prosinec"
	],
	days: [
		"Neděle",
		"Pondělí",
		"Úterý",
		"Středa",
		"Čtvrtek",
		"Pátek",
		"Sobota"
	]
};
$.datePicker.defaults.dateFormat = function (date) {
	var y = date.getFullYear();
	var m = date.getMonth() + 1;
	var d = date.getDate();
	return y + "-" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d;
};
$.datePicker.defaults.dateParse = function (string) {
	if (string instanceof Date) {
		return string;
	}
	var date = new Date();
	var parts = string.split("-");
	if (parts.length === 3) {
		date.setFullYear(parts[0]);
		date.setMonth(parts[1] - 1);
		date.setDate(parts[2]);
	}
	return date;
};
$.datePicker.defaults.views.month.firstDayOfWeek = 1;
