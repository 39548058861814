$(function () {
	$(".item-list-less-more").each(function () {
		var items = $(this).children(".item:not(.less-more-link)").slice(3);
		if (items.length > 0) {
			var linkMore = $(this).find(".more-link");
			var linkLess = $(this).find(".less-link");

			var show = function () {
				items.removeClass("less-more-hidden");
				linkMore.addClass("less-more-hidden");
				linkLess.removeClass("less-more-hidden");
			};
			var hide = function () {
				items.addClass("less-more-hidden");
				linkMore.removeClass("less-more-hidden");
				linkLess.addClass("less-more-hidden");
			};

			hide();
			linkMore.click(show);
			linkLess.click(hide);
		}
	});
});
