var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Item slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides-max') ? sliders[id]['slideNumMax'] = el.attr('data-slides-max') : sliders[id]['slideNumMax'] = 1;
			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = sliders[id]['slideNumMax'];
			el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumTablet'];
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
			el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
			el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: sliders[id]['slideAuto'],
					autowidth: false,
					continuous: true,
					prevNext: sliders[id]['slideNav'],
					numeric: sliders[id]['slideNumeric'],
					slideCount: sliders[id]['slideNum'],
					speed: 250,
					responsive: true,
					customLink: 'a.customLink',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});

		$(window).on("resizeEnd focus", function () {
			for (var i in sliders) {
				if (!sliders.hasOwnProperty(i))
					continue;

				var width = $(window).width();

				var orgSlideCount = sliders[i]['slider'].getOption("slideCount");
				var slideCount;

				if (width >= 1441) {
					slideCount = sliders[i]['slideNumMax']; // 1441 - 1920
				}
				else if (width >= 1200) {
					slideCount = sliders[i]['slideNum']; // 1200 - 1440
				}
				else if (width >= 992) {
					slideCount = sliders[i]['slideNumDesktop']; // 992 - 1199
				}
				else if (width >= 768) {
					slideCount = sliders[i]['slideNumTablet']; // 768 - 991
				}
				else {
					slideCount = sliders[i]['slideNumMobile']; // 0 - 767
				}
				if (slideCount != orgSlideCount) {
					sliders[i]['slider'].setOption("slideCount", slideCount);
				}
			};
		}).resize();

		// Teaser slider
		$('.teaser-slider').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				var auto = true;
   				var autostopped = false;

				teaserSlider = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: true,
					pause: 10000,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: true,
					slideCount: 1,
					speed: 250,
					responsive: true,
					//customLink: '.teaser-slider .switch a',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
				teaserSlider.mouseenter(function() {
			      	auto = teaserSlider.getValue('autoAnimation');
			      	if (auto) {
			         	teaserSlider.stopAuto();
			      	} else {
			         	autostopped = true;
			      	}
			   	}).mouseleave(function() {
			      	if (!autostopped) {
			         	teaserSlider.startAuto();
			      	}
			   	});
			}
		});

	}

});
