$(function () {

	var actualSubjectLevel = "";
	var select = $(".lecture-tip-filter #subjectlevel");

	var update = function () {
		var subjectLevel = select.val();
		if (subjectLevel === actualSubjectLevel) {
			return;
		}
		actualSubjectLevel = subjectLevel;

		var showTips = subjectLevel !== "none";

		$("#lecture-tips-content").toggle(showTips);
		$("#lecture-themes-content").toggle(!showTips);

		$.ajax({
			url: "/lekce/tipy",
			data: { subjectlevel: subjectLevel },
			type: "GET",
			dataType: "html",
			cache: false,
			success: function (response) {
				if (showTips && subjectLevel === actualSubjectLevel) {
					$("#lecture-tips-content").html(response);
				}
			}
		});
		if (!showTips) {
			$("#lecture-tips-content").empty();
		}
	};

	select
		.change(update)
		.trigger("change");

	var tabFirstClicked = true;
	$(".tab-nav-lectures a")
		.click(function () {
			if (tabFirstClicked) {
				tabFirstClicked = false;
				if (window.location.hash !== "") {
					return;
				}
			}
			select.val("none").trigger("change");
		});

	$(document)
		.on("mouseenter", ".lecture-tip", function () {
			var height = $(this).find(".lecture-tip-inner").outerHeight();
			$(this).clearQueue().animate({ height: height }, 300);
		})
		.on("mouseleave", ".lecture-tip", function () {
			$(this).clearQueue().animate({ height: 120 }, 300);
		});

	var updateFilterVisibility = function () {
		setTimeout(function () {
			$(".lecture-tip-filter").toggle($(".tab-nav-lectures .selected").attr("href") === "#tema");
		}, 1);
	};
	updateFilterVisibility();
	$(".tab-nav-lectures a").click(updateFilterVisibility);
});