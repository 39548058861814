$(function () {

	function initialize() {
		$(".select-autosubmit").each(function () {
			var form = $(this).find("form");
			form.find("select")
				.change(function () {
					if ($(this).val()) {
						form.submit();
					}
				});
		});
	}
	initialize();

	var orig = initSelect2;
	initSelect2 = function () {
		orig();
		initialize();
	};

});
