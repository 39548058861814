$(document).ready(function ($) {

	$('body').on('click', 'a.ajax', function () {
		$(this).myAjax($(this).attr('data-to-inject'));
		return false;
	});
	$('body').on('submit', 'form.ajax', function () {
		$(this).myAjax($(this).attr('data-to-inject'));
		return false;
	});

	//Ajax Box
	var openBox = function (element) {
		var load = element ? element : $(this);
		var box = load.parents('.ajax-box').first();
		var parent = box.parent('.boxes');

		if (box.hasClass('loading') || box.hasClass('opened') || (parent.attr('data-single') && (parent.hasClass('has-loading') || parent.hasClass('has-opened')))) {
			return;
		}
		if (box.hasClass('loaded')) {
			box.addClass('opened');
			parent.addClass('has-opened');
			return;
		}
		box.addClass('loading');
		parent.addClass('has-loading');
		load.myAjax(load.attr('data-to-inject'), false, function (data) {
			box.removeClass('loading');
			parent.removeClass('has-loading');
			if (!data) {
				return;
			}
			box.addClass('loaded').addClass('opened').parent('.boxes').addClass('has-opened');
			box.find('*[data-content]').each(function () {
				var content = $(this);
				var selector = content.attr('data-content');
				if (data.snippets && data.snippets[selector]) {
					content.html(data.snippets[selector]['body']);
				}
			})
		});
	};

	$('body').on('click', '.ajax-box *[data-load]', function(){openBox($(this))});
	$('body').on('click', '.ajax-box *[data-close]', function () {
		var box = $(this).parents('.ajax-box').first();
		if (box.hasClass('loading')) {
			return;
		}
		box.removeClass('opened').parent('.boxes').removeClass('has-opened');
	});

	var hash = window.location.hash;
	if (hash) {
		if (hash.substring(0, 1) != '#') {
			hash = '#' + hash;
		}
		var link = $(hash + '.ajax-box [data-content]');
		if (link.length){
			openBox(link);
		}
	}
});

//send form or href click via ajax
//on forms allows injection or change of values
$.fn.myAjax = function (toInject, hasLoader, callback) {
	if (typeof hasLoader == 'undefined') {
		hasLoader = true;
	}
	var url = null;
	var requestData = null;
	var form = null;
	if (this.prop('tagName').toLowerCase() == 'form') {
		form = this.closest('form');
		url = form.attr('action');
		if (typeof toInject === 'object') {
			for (var key in toInject) {
				//change a value if element exits
				if ($("input[name='" + key + "']", form).length > 0) {
					$("input[name='" + key + "']", form).val(toInject[key]);
				} else {//or insert a new one if it doesn't
					if (toInject.hasOwnProperty(key)) {
						$('<input>').attr({
							type: 'hidden',
							id: key,
							name: key,
							value: toInject[key]
						}).appendTo(form);
					}
				}
			}
		}
		requestData = form.serializeArray();
		if (form.prop('enctype') == 'multipart/form-data') {
			var formData = new FormData();
			for (var p in requestData) {
				formData.append(requestData[p].name, requestData[p].value);
			}
			jQuery.each($('input[type=file]', form), function (i, input) {
				if (input.files.length) {
					formData.append(input.name, input.files[0]);
				}
			});
			requestData = formData;
		}
		if (this.attr('data-persist-to-hash')) {
			disableHashChange = true;
			//add formId element with id value
			$('<input>').attr({type: 'hidden', name: 'formId', value: this.attr('id')}).appendTo(form);

			window.location.hash = '#' + $.param($('#' + this.attr('id')).values());
		}
	} else {
		url = this.attr('href');
		if (!url && this.attr('data-load')) {
			url = this.attr('data-load');
		}
		requestData = toInject;
		if (this.attr('data-persist-to-hash') && this.attr('id')) {
			disableHashChange = true;
			window.location.hash = '#' + this.attr('id');
		}
	}

	if (hasLoader) {
		$('.loader-overlay').show();
	}

	var ajaxOptions = {
		url: (url) ? url : window.location.href,
		type: (form === null) ? 'GET' : 'POST',
		data: requestData
	};
	if (form && form.prop('enctype') == 'multipart/form-data') {
		ajaxOptions.contentType = false;
		ajaxOptions.processData = false;
		ajaxOptions.cache = false;
	}

	var hideLoader = true;
	$.ajax(ajaxOptions).done(function (responseData) {
		//handle snippets
		var popup = null;
		var evalScript = null;
		var index = null;
		var process = true;
		if (callback) {
			process = callback(responseData);
		}
		if (process !== false){
			for (var key in responseData.snippets) {
				switch (responseData.snippets[key].type) {
					case 'element': {
						$(key).html(responseData.snippets[key].body);
					}
						break;
					case 'element-append': {
						$(key).append(responseData.snippets[key].body);
					}
						break;
					case 'popup': {
						popup = responseData.snippets[key].body;
					}
						break;
					case 'popup-close': {
						$.fancybox.close();
					}
						break;
					case 'redirect': {
						document.location.href = responseData.snippets[key].body;
						hideLoader = false;
					}
						break;
					case 'redirect-ajax': {
						var url = responseData.snippets[key].body;
						var trigger = $('<a href="' + url + '" class="ajax"></a>').appendTo('body');
						trigger.click();
						trigger.remove();
						hideLoader = false;
					}
						break;
					case 'remove': {
						if (typeof responseData.snippets[key].body === 'object') {
							for (index in responseData.snippets[key].body) {
								$(responseData.snippets[key].body[index]).remove();
							}
						} else {
							$(responseData.snippets[key].body).remove();
						}
					}
						break;
					case 'hide': {
						if (typeof responseData.snippets[key].body === 'object') {
							for (index in responseData.snippets[key].body) {
								$(responseData.snippets[key].body[index]).hide();
							}
						} else {
							$(responseData.snippets[key].body).hide();
						}
					}
						break;
					case 'show': {
						if (typeof responseData.snippets[key].body === 'object') {
							for (index in responseData.snippets[key].body) {
								$(responseData.snippets[key].body[index]).show();
							}
						} else {
							$(responseData.snippets[key].body).show();
						}
					}
						break;
					case 'populate': {
						if ($(key).length && $(key).is('form')) {
							$(key).values(responseData.snippets[key].body.data, responseData.snippets[key].body.prefix);
							if ($(key).attr('data-js-validation')) {
								$(key).valid();
							}
						}
					}
						break;
					case 'eval': {
						evalScript = responseData.snippets[key].body;
					}
						break;
				}
				//reload on null snippet
				//if (responseData[key] == null) window.location.reload();
			}
		}
		//check if there was a popup
		if (popup !== null) {
			if (hasLoader) {
				$('.loader-overlay').hide();
			}
			if ($.fn.fancybox) {
				$.fancybox.open(popup, defaultFancyboxConfig);
			}
		}
		//check if there was an eval
		if (evalScript !== null) {
			eval(evalScript);
		}
	}).fail(function () {
		if (callback) {
			callback(false);
		} else {
			alert('That is an error.');
		}
	}).always(function () {
		afterAjax();
		if (hideLoader) {
			if (hasLoader) {
				$('.loader-overlay').hide();
			}
		}
	});
};

function afterAjax()
{
	//updateUploadWidgets();

	initJsValidation();

	// Stejná výška elementů
	$(window).resize();

	// Close FM
	initCloseFM();

	initFixedHeaderTable();
}

(function () {

	var update = function () {
		$("[data-inject-size-by]").each(function () {
			var elm = $(this);
			var by = $(elm.attr("data-inject-size-by"));
			var width = by.innerWidth();
			var height;
			if (elm.attr("data-inject-height-by-width")) {
				height = Math.round(width * Number(elm.attr("data-inject-height-by-width")));
			} else {
				height = by.innerHeight();
			}
			elm.attr("data-to-inject", "width=" + width + "&height=" + height);
		});
	};
	$(window).resize(update);
	update();

})();

function playerResize(player, containerId)
{
	var container = $("#" + containerId);
	var width = container.innerWidth();
	var height = container.innerHeight();
	var newWidth = Math.max(container.parent().innerWidth(), 300);
	height = newWidth / width * height;
	width = newWidth;
	player.resize(width, height);
}
