(function ($) {
	$(function () {
		$(".toggle-texts").each(function () {
			var items = $(this).find(".text");
			var titles = items.find(".title");
			var contents = items.find(".content");

			contents
				.each(function () {
					var content = $(this);
					content.data("height", content.innerHeight());
				});

			var opened = null;

			titles.click(function () {
				if (opened !== null) {
					var height = opened.find(".content").innerHeight();
					opened
						.find(".content")
						.data("height", height)
						.css("height", height)
						.animate({ height: 0 }, 200, function () {
							$(this)
								.css("height", "")
								.closest(".text")
								.removeClass("open");
						});
				}
				var text = $(this).closest(".text");
				if (text.is(opened)) {
					opened = null;
					return;
				}
				opened = text;
				text.addClass("open");
				var content = opened.find(".content");
				content
					.css("height", 0)
					.animate({ height: content.data("height") }, 200, function () {
						$(this).css("height", "");
					});
			});
		});
	});
})(jQuery);
